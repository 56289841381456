<template>
  <validation-observer>
    <div class="card card-custom">
      <div class="card-header border-0 pt-6 pb-0">
        <div class="card-title align-items-center justify-content-between ">
          <h3 class="card-label font-weight-bolder mb-0">
            {{ $t("Information") }}
          </h3>
        </div>
        <div v-if="status == 'draft'" class="card-toolbar">
          <div class="d-flex align-items-center">
            <div class="font-weight-bold">
              {{ $t("Status") }}:
            </div>
            <div class="ml-5">
              <secondment-status-button block :status="status" :secondment="secondment"
                @update-status="$emit('update-status', $event)"></secondment-status-button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="description" class="card-custom">
        <div class="card-header border-0 pt-4 pb-0">
          <div class="card-title align-items-center justify-content-between ">
            <h3 class="my-6">
              {{ $t("Description") }}
            </h3>
          </div>
        </div>
        <div class="card-header border-0 pt-0 pb-0">
          <div class="font-weight-bold text-capitalize mb-2">
            {{ description }}
          </div>
        </div>
      </div>
      <div class="card-body detail">
        <b-container class="px-0">
          <b-row>
            <b-col cols="12">
              <CountryStatisticsCardHorizontal :b-card-bindings="{ 'border-variant': 'transparent' }"
                :country-iso="country" :active-subs="activeSubs" :pending-subs="pendingSubs"
                @update-capabilities="$emit('update-capabilities')" @go-to-shop="(e) => $emit('go-to-shop', e)"
                @update-selected-color="handleUpdateSelectedColor">
              </CountryStatisticsCardHorizontal>
            </b-col>
            <b-col cols="12">
              <secondment-world-map-chart :selected-country="country"
                :highlighted-countries="countriesWithSecondmentService" class="map-height"></secondment-world-map-chart>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>
  </validation-observer>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import PostAccessService from "@/core/services/risk/post-access.service";
import SecondmentStatusButton from "@/view/components/buttons/SecondmentStatusButton.vue";
import { STATUS_TYPE } from "@/core/abstractions/secondment.js";
import { backendErrorSwal } from "@/core/helpers/swal";
import { mapGetters, mapState } from "vuex";
import icons from "@/core/config/icons";
import CountryStatisticsCardHorizontal from "@/view/components/cards/CountryStatisticsCardHorizontal.vue";
export default {
  components: {
    CountryStatisticsCardHorizontal,
    SecondmentWorldMapChart: () => import(/* webpackChunkName: "SecondmentWorldMapChart" */ "@/view/components/charts/SecondmentWorldMapChart.vue"),
    SecondmentStatusButton,
  },

  props: {
    secondment: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      icons,
      STATUS_TYPE,
      country: "",
      dateStart: null,
      dateEnd: null,
      activeSubs: {},
      pendingSubs: {},
    };
  },

  computed: {
    ...mapState("constants", ["countries"]),
    ...mapGetters("constants", ["countriesWithSecondmentService"]),

    status() {
      return this.secondment.status;
    },

    description() {
      return this.secondment.description;
    },

    countriesSelectable() {
      return this.countries
        .map((x) => ({ text: this.$t(x.name), value: x.iso }))
        .sort((a, b) => a.text.localeCompare(b.text));
    },
  },

  async created() {
    this.reset();
    await this.getCapabilities();
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("Assignments") },
      { title: this.$t("Information") },
    ]);
  },

  methods: {
    goToShop({ country, product }) {
      const route = {
        name: "shop",
      };
      route.query = { product, country };
      this.$router.push(route);
    },

    async getCapabilities() {
      try {
        const results = await PostAccessService.getCapabilities();

        results.subscriptions.forEach((sub) => {
          if (sub.active) {
            const categories = sub.querytemplate.categories;
            const countries = sub.querytemplate.post_countries;
            categories.forEach((cat) => {
              if (!this?.activeSubs[cat]) {
                this.activeSubs[cat] = countries;
              } else {
                const catSet = new Set([...this.activeSubs[cat], ...countries]);
                this.activeSubs[cat] = Array.from(catSet);
              }
            });
          }
        });

        results.pending_capabilities.forEach((sub) => {
          const categories = sub.categories.map((cate) => cate.id);
          const country = sub.country;
          categories.forEach((cat) => {
            if (!this?.pendingSubs[cat]) {
              this.pendingSubs[cat] = country;
            } else {
              const pending =
                typeof this.pendingSubs[cat] == "string" ? [this.pendingSubs[cat]] : this.pendingSubs[cat];
              const catSet = new Set([...pending, country]);
              this.pendingSubs[cat] = Array.from(catSet);
            }
          });
        });
      } catch (err) {
        console.error(err);
        backendErrorSwal(err, err?.response?.data?.detail);
        this.countriesToHighlight = [];
      }
    },

    async reset() {
      await this.$nextTick();

      this.country = this.secondment.country;
      this.dateStart = this.secondment.date_start;
      this.dateEnd = this.secondment.date_end;
    },
    handleUpdateSelectedColor(risk_level) {
      // Emit a custom event to be listened by the SecondmentsMapChart component.
      this.$emit('update-color-in-map', risk_level);
    },
  },
};
</script>

<style scoped>
.map-height {
  height: 250px;
}
</style>
