var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('validation-observer', [_c('div', {
    staticClass: "card card-custom"
  }, [_c('div', {
    staticClass: "card-header border-0 pt-6 pb-0"
  }, [_c('div', {
    staticClass: "card-title align-items-center justify-content-between"
  }, [_c('h3', {
    staticClass: "card-label font-weight-bolder mb-0"
  }, [_vm._v(" " + _vm._s(_vm.$t("Information")) + " ")])]), _vm.status == 'draft' ? _c('div', {
    staticClass: "card-toolbar"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t("Status")) + ": ")]), _c('div', {
    staticClass: "ml-5"
  }, [_c('secondment-status-button', {
    attrs: {
      "block": "",
      "status": _vm.status,
      "secondment": _vm.secondment
    },
    on: {
      "update-status": function updateStatus($event) {
        return _vm.$emit('update-status', $event);
      }
    }
  })], 1)])]) : _vm._e()]), _vm.description ? _c('div', {
    staticClass: "card-custom"
  }, [_c('div', {
    staticClass: "card-header border-0 pt-4 pb-0"
  }, [_c('div', {
    staticClass: "card-title align-items-center justify-content-between"
  }, [_c('h3', {
    staticClass: "my-6"
  }, [_vm._v(" " + _vm._s(_vm.$t("Description")) + " ")])])]), _c('div', {
    staticClass: "card-header border-0 pt-0 pb-0"
  }, [_c('div', {
    staticClass: "font-weight-bold text-capitalize mb-2"
  }, [_vm._v(" " + _vm._s(_vm.description) + " ")])])]) : _vm._e(), _c('div', {
    staticClass: "card-body detail"
  }, [_c('b-container', {
    staticClass: "px-0"
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('CountryStatisticsCardHorizontal', {
    attrs: {
      "b-card-bindings": {
        'border-variant': 'transparent'
      },
      "country-iso": _vm.country,
      "active-subs": _vm.activeSubs,
      "pending-subs": _vm.pendingSubs
    },
    on: {
      "update-capabilities": function updateCapabilities($event) {
        return _vm.$emit('update-capabilities');
      },
      "go-to-shop": function goToShop(e) {
        return _vm.$emit('go-to-shop', e);
      },
      "update-selected-color": _vm.handleUpdateSelectedColor
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('secondment-world-map-chart', {
    staticClass: "map-height",
    attrs: {
      "selected-country": _vm.country,
      "highlighted-countries": _vm.countriesWithSecondmentService
    }
  })], 1)], 1)], 1)], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }